<template>
  <div :class="$style.component">
    <div :class="$style.content">
      <base-bodytext :class="$style.body" :text="item.body" />

      <div v-if="item.caption" v-html="item.caption" :class="classes.caption" />
    </div>

    <transition name="t-section">
      <div v-if="showSectionTitle" :class="$style.wrapper">
        <span v-html="item.sectionTitle" :class="$style.sectionTitle" />
      </div>
    </transition>
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'

export default {
  components: { BaseBodytext },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCurrent: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    classes() {
      return {
        caption: [
          this.$style.caption,
          this.isCurrent ? this.$style.isCurrent : ''
        ]
      }
    },
    showSectionTitle() {
      return this.isCurrent && this.item.sectionTitle
    }
  }
}
</script>

<style lang="scss" scoped>
.t-section-enter-active,
.t-section-leave-active {
  transition: opacity var(--long) linear;
}

// to avoid flashing when there are two identicla section titles
.t-section-leave-active {
  transition-delay: var(--xshort);
}

.t-section-enter,
.t-section-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.component {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  position: relative;
  max-width: 28em;
}

.body {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-small;

  text-transform: lowercase;
}

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0 var(--gutter) var(--gutter) var(--gutter);
  // margin-bottom: -0.3em; // to align section title with lower border of cpation
}

.sectionTitle {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  text-transform: lowercase;
}

.caption {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-caption;

  margin-top: var(--gutter);
  opacity: 0;
  transition: opacity var(--long) linear;

  &.isCurrent {
    opacity: 1;
  }

  @media (min-width: $medium) {
    position: absolute;
    bottom: calc(var(--grid-gap) * -1);
    transform: translateY(100%);
  }
}
</style>
